var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "landing-central-card",
    [
      _c(
        "v-card",
        {
          staticClass: "federated_signout-card flexcard",
          style: _vm.cardStyles,
          attrs: {
            elevation: _vm.elevation,
            "min-height": _vm.cardHeight,
            flat: ""
          }
        },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "justify-center": "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "mb-2 text-center",
                      attrs: { xs8: "", sm5: "" }
                    },
                    [_c("registration-complete-image")],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "justify-center": "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "mb-2 text-center",
                      attrs: { xs12: "", sm11: "", md10: "" }
                    },
                    [
                      _c("h1", { staticClass: "starling-h1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("public.federated.sign_out.success.title")
                          )
                        )
                      ]),
                      _c("p", { staticClass: "starling-body" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("public.federated.sign_out.success.content")
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }