





















import Vue from 'vue';
import LandingCentralCard from '@/views/landing/landing-central-card.vue';
import RegistrationCompleteImage from '@/views/images/success-image.vue';

export default Vue.extend({
  name: 'FederatedSignOut',
  components: { RegistrationCompleteImage, LandingCentralCard },
  props: [ 'elevation', 'cardHeight', 'cardStyles' ],
  data() {
    return {
      valid: false,
      error: null,
    };
  },
  mounted() {
    setTimeout(() => {
      this.$router.replace({ name: 'home' });
    }, 3000);
  },
});
