var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "175",
        height: "129",
        viewBox: "0 0 175 129",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M104.13 74.8489C104.13 74.8489 86.1798 106.828 85.8695 107.114C84.8446 108.102 83.2347 108.05 82.276 107.01L70.6484 96.0381C69.6871 94.9982 69.7405 93.3342 70.7654 92.3723C71.7903 91.3843 73.4002 91.4363 74.3615 92.4763L83.4611 101.056L99.7249 72.249C100.427 71.0271 101.981 70.5851 103.199 71.313C104.415 72.015 104.832 73.627 104.13 74.8489ZM88 49C65.91 49 48 66.9 48 89C48 111.1 65.91 129 88 129C110.09 129 128 111.1 128 89C128 66.9 110.09 49 88 49Z",
          fill: "#08EACF"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M58.4726 20.7656C55.4736 20.7656 53.0439 18.3346 53.0439 15.3357C53.0439 12.3367 55.4736 9.90576 58.4726 9.90576C61.4728 9.90576 63.9025 12.3367 63.9025 15.3357C63.9025 18.3346 61.4728 20.7656 58.4726 20.7656Z",
          stroke: "#EDEDED",
          "stroke-width": "4"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M156.007 104.586C153.008 104.586 150.578 102.155 150.578 99.1558C150.578 96.1568 153.008 93.7258 156.007 93.7258C159.007 93.7258 161.437 96.1568 161.437 99.1558C161.437 102.155 159.007 104.586 156.007 104.586Z",
          stroke: "#FFB843",
          "stroke-width": "4"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M168.452 116.885L168.456 116.889C168.919 117.352 169.669 117.352 170.133 116.889L171.479 115.543L172.825 116.889C173.288 117.353 174.038 117.353 174.5 116.889L174.504 116.886C174.968 116.423 174.968 115.672 174.504 115.21L173.158 113.863L174.504 112.517C174.968 112.054 174.968 111.304 174.504 110.842L174.501 110.838C174.038 110.374 173.287 110.374 172.825 110.838L171.479 112.183L170.133 110.837C169.669 110.375 168.919 110.375 168.457 110.837L168.453 110.841C167.99 111.304 167.99 112.054 168.453 112.518L169.798 113.863L168.452 115.209C167.99 115.673 167.99 116.423 168.452 116.885Z",
          fill: "#2FCEFB"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M18.7653 93.6414C20.9436 93.6414 22.7095 91.8755 22.7095 89.6972C22.7095 87.5188 20.9436 85.7529 18.7653 85.7529C16.5869 85.7529 14.821 87.5188 14.821 89.6972C14.821 91.8755 16.5869 93.6414 18.7653 93.6414Z",
          fill: "#FF777E"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M146.756 36.4439C148.934 36.4439 150.7 34.678 150.7 32.4996C150.7 30.3213 148.934 28.5554 146.756 28.5554C144.577 28.5554 142.812 30.3213 142.812 32.4996C142.812 34.678 144.577 36.4439 146.756 36.4439Z",
          fill: "#FF777E"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M165.093 53.9891C166.672 53.9891 167.951 52.7095 167.951 51.1311C167.951 49.5527 166.672 48.2732 165.093 48.2732C163.515 48.2732 162.235 49.5527 162.235 51.1311C162.235 52.7095 163.515 53.9891 165.093 53.9891Z",
          fill: "#EDEDED"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M116.098 36.1959C117.677 36.1959 118.956 34.9163 118.956 33.3379C118.956 31.7595 117.677 30.48 116.098 30.48C114.52 30.48 113.24 31.7595 113.24 33.3379C113.24 34.9163 114.52 36.1959 116.098 36.1959Z",
          fill: "#2FCEFB"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M35.171 6.39474C36.9369 6.39474 38.3684 4.96323 38.3684 3.19737C38.3684 1.43151 36.9369 0 35.171 0C33.4051 0 31.9736 1.43151 31.9736 3.19737C31.9736 4.96323 33.4051 6.39474 35.171 6.39474Z",
          fill: "#FFB843"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M2.85795 77.7059C4.43636 77.7059 5.7159 76.4263 5.7159 74.8479C5.7159 73.2695 4.43636 71.99 2.85795 71.99C1.27955 71.99 0 73.2695 0 74.8479C0 76.4263 1.27955 77.7059 2.85795 77.7059Z",
          fill: "#EDEDED"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M21.9357 52.0845C24.114 52.0845 25.8799 50.3186 25.8799 48.1403C25.8799 45.9619 24.114 44.196 21.9357 44.196C19.7573 44.196 17.9915 45.9619 17.9915 48.1403C17.9915 50.3186 19.7573 52.0845 21.9357 52.0845Z",
          stroke: "#2FCEFB",
          "stroke-width": "3"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }